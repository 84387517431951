<template>
  <div class="lights">
    <div class="light-container" :class="{'is-red': light.state === 0, 'is-yellow': light.state === 1, 'is-green': light.state === 2}" v-for="(light, index) of lights" :key="index">
      <div class="light">
        <div class="is-red" :class="{'is-active': light.state === 0}"></div>
        <div class="is-yellow" :class="{'is-active': light.state === 1}"></div>
        <div class="is-green" :class="{'is-active': light.state === 2}"></div>
      </div>
      <h1>{{ randomText(light.state)}}</h1>
      <footer><strong> design by <a href="https://jossafossa.nl"> Joost Hobma </a></strong></footer>
    </div> 
  </div>
</template>

<script>
import db from "@/firebase/db.js";


export default {
  name: 'App',
  data: function() {
    return {
      text: [
        [
          "CvL is helaas dicht :(",
          ":( :( :( :( :( :(",
          "Sad life",
          "Das kut, CvL fut",
          "Jammerrrr.."
        ],
        [
          "CvL gaat bijna sluiten",
          "Wees snel! CvL is bijna dicht",
          "Even een sprintje trekken want CvL gaat sluiten",
          "Opschieten!",
        ],
        [
          "Tijd voor feest want CvL is open",
          "CvL is open!",
          "Kom nu je koffie halen!",
        ],
      ],
      lights: [],
    }
  },
  components: {
  },
  methods: {
    randomText(index) {
      let items = this.text[index];
      let text = items[Math.floor(Math.random()*items.length)];
      console.log(text);
      return text;
    }
  },
  computed: {
    rand() {
      return Math.floor(Math.random() * 100);
    }
  },
  firestore: {
    lights: db.collection('lights'),
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

@mixin aspect($x, $y) {
  position: relative;
  padding-bottom: calc(100% / $x * $y);
  width:100%;

  > * {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
}

* {
  box-sizing: border-box;
}

body {
  // height:100vh;
  // width:100vw;
  margin:0;
  padding:0;

  font-family: 'Montserrat', sans-serif;
  color:white;
}

h1 {
  margin:0;
}
footer {
    position: absolute;
    bottom: 1%;
    color: #fff;
    z-index: 9999999;
}
footer,
strong {
    text-align: center;
}
a {
    color: #fff;
}
.light-container {
  height:100vh;
  width:100vw;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    background-color: #2c2d35;

    h1 {
      text-align: center;
      margin:0 2rem;
    }


  &:before {
    content:"";
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    // background-image: radial-gradient(circle, transparent 10%, var(--color) 50%, transparent 100% );
    opacity: 0.05;
    z-index:1;
  }


  > *:not(:nth-last-child(1)) {
    margin-bottom: 2rem;
  }

    &.is-red {
      --color: #f94a1c;
    }

    &.is-yellow {
      --color: #ff9e18;
    }

    &.is-green {
      --color: #0ad05e;
    }
}

.light {
  background-color: #22212a;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  
  width: 30vmax;
  height: 60vmax;
  z-index: 2;;
  border-radius: 3vmax;
  box-shadow: 0 0 0 2vmax rgba(0,0,0,0.3) inset, 0 2vmax 3vmax rgba(0,0,0,0.3 );

@media screen and (min-width:500px) {
  width: 30vmin;
  border-radius: 3vmin;
  box-shadow: 0 0 0 2vmin rgba(0,0,0,0.3) inset, 0 2vmin 3vmin rgba(0,0,0,0.3 );
  height: 60vmin;
}


  > * {
    --size: 40%;
    --opacity: 0.2;
    margin:7.5%;
    border-radius: 50%;
    width:var(--size);
    padding-bottom: var(--size);
    background-color:#2a1e1e;
    transition:1s ease;
    position:relative;
    box-shadow: 0 0 1rem transparent;
    overflow: hidden;

    &:after {
      content:"";
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      opacity:var(--opacity);
      background-image: radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.2) 80%, rgba(255,255,255,0) 100%);
      background-color:var(--color);
      transition:1s ease;
    }

    &.is-active {
      --opacity:1;

    box-shadow: 0 0 10vmin 4vmin var(--color);
    }

    &.is-red {
      --color: #f94a1c;
    }

    &.is-yellow {
      --color: #ff9e18;
    }

    &.is-green {
      --color: #0ad05e;
    }
  }
}
</style>
